import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BackofficeUserContextService } from '../../services/user-context/backoffice-user-context.service';
import { BackofficeRouteNavigationService } from '../backoffice-route-navigation.service';
import { take } from 'rxjs/operators';

@Injectable()
export class LoggedInRouteGuard implements CanActivate {
  constructor(private _userContextService: BackofficeUserContextService,
              private _routeNavigationService: BackofficeRouteNavigationService) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this._userContextService.isUserLoggedInObservable
        .pipe(take(1))
        .subscribe((isUserLoggedIn: boolean) => {
          if (!isUserLoggedIn) {
            this._userContextService.saveRouteToNavigateAfterLogin(state.url);

            this._routeNavigationService.goToLoginAsync();
          }

          resolve(isUserLoggedIn);
        });
    });
  }
}
