<div id="app">
  <div>
    <app-header></app-header>
  </div>

  <div id="content-container">
    <router-outlet></router-outlet>
  </div>

  <div>
    <app-footer></app-footer>
  </div>
</div>