<div>
  <h3>Users</h3>

  <div class="d-flex flex-row">
    <button mat-raised-button color="primary" (click)="generateInviteLinkAsync()">
      Generate invite link
    </button>

    <div *ngIf="inviteLink" class="ml-3 flex-grow-1">
      <mat-form-field class="w-100">
        <input matInput #linkInput [value]="inviteLink" readonly (click)="linkInput.select()">
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="companyUsers" class="mt-3">
    <table class="table table-striped">
      <tr>
        <th>Id</th>
        <th>Email</th>
        <th>Name</th>
        <th>Permission Role</th>
      </tr>

      <tr *ngFor="let companyUser of companyUsers">
        <td>{{ companyUser.id }}</td>
        <td>{{ companyUser.user.email }}</td>
        <td>{{ companyUser.user.name }}</td>
        <td>
          <mat-form-field>
            <mat-label>Permission Role</mat-label>
            <mat-select [value]="companyUser.permissionRole.id"
                        (selectionChange)="onCompanyUserRoleChange(companyUser, $event)">
              <mat-option *ngFor="let role of permissionRoles" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>
</div>