<div *ngIf="companies" class="container">
  <div class="mb-3 text-right">
    <mat-form-field>
      <input matInput #createCompanyName placeholder="Company name" required>
    </mat-form-field>
    <mat-form-field class="ml-3">
      <input matInput #createCompanyIdentifierName placeholder="Identifier name" required>
    </mat-form-field>

    <button mat-raised-button
            class="ml-3"
            color="primary"
            (click)="createCompanyAsync(createCompanyName.value, createCompanyIdentifierName.value, createCompanyName, createCompanyIdentifierName)">
      Create Company
    </button>
  </div>

  <table class="table table-striped">
    <tr>
      <th>Id</th>
      <th>Name</th>
      <th>Identifier Name</th>
      <th>Actions</th>
    </tr>

    <tr *ngFor="let company of companies">
      <td>{{ company.id }}</td>
      <td>{{ company.name }}</td>
      <td>{{ company.identifierName }}</td>
      <td>
        <button mat-raised-button (click)="editCompanyClickAsync(company.id)">
          Edit
        </button>
      </td>
    </tr>
  </table>
</div>