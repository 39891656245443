<div>
  <h3>Settings</h3>

  <div class="mt-3" *ngIf="settings">
    <div>
      <span>Max number of users:</span>

      <mat-form-field class="ml-3">
        <input matInput name="max-number-of-users" placeholder="Max number of users"
               type="number" [(ngModel)]="settings.maxNumberOfCompanyUsers" required>
      </mat-form-field>
    </div>
    <div>
      <span>Max number of procedures:</span>

      <mat-form-field class="ml-3">
        <input matInput name="max-number-of-procedures" placeholder="Max number of procedures"
               type="number" [(ngModel)]="settings.maxNumberOfProcedures" required>
      </mat-form-field>
    </div>
  </div>
  <div class="mt-3 text-right">
    <button mat-raised-button color="primary" (click)="updateSettingsAsync()">
      Save
    </button>
  </div>
</div>