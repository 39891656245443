import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyListComponent } from './company-list/company-list.component';
import { CompanyAnalyticsComponent } from './company/company-analytics/company-analytics.component';
import { CompanyPartnerSettingsComponent } from './company/company-partner-settings/company-partner-settings.component';
import { CompanySubscriptionSettingsComponent } from './company/company-subscription-settings/company-subscription-settings.component';
import { CompanySubscriptionsComponent } from './company/company-subscriptions/company-subscriptions.component';
import { CompanyUsersComponent } from './company/company-users/company-users.component';
import { CompanyComponent } from './company/company.component';
import { LoginComponent } from './login/login.component';
import { BackofficeRouteLocations } from './shared/routing/backoffice-route-locations.enum';
import { LoggedInRouteGuard } from './shared/routing/route-guards/logged-in.guard';

const routes: Routes = [
  {
    path: BackofficeRouteLocations.Login,
    component: LoginComponent,
  },
  {
    path: BackofficeRouteLocations.CompanyList,
    component: CompanyListComponent,
    pathMatch: 'full',
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: BackofficeRouteLocations.CompanyListChildrenBase,
    component: CompanyComponent,
    children: [
      {
        path: BackofficeRouteLocations.CompanyListChildren.CompanyUsers,
        component: CompanyUsersComponent,
      },
      {
        path: BackofficeRouteLocations.CompanyListChildren.CompanySubscriptions,
        component: CompanySubscriptionsComponent,
      },
      {
        path: BackofficeRouteLocations.CompanyListChildren.CompanySubscriptionSettings,
        component: CompanySubscriptionSettingsComponent,
      },
      {
        path: BackofficeRouteLocations.CompanyListChildren.CompanyAnalytics,
        component: CompanyAnalyticsComponent,
      },
      {
        path: BackofficeRouteLocations.CompanyListChildren.PartnerSettings,
        component: CompanyPartnerSettingsComponent,
      },
      {
        path: '**',
        redirectTo: BackofficeRouteLocations.CompanyListChildren.CompanyUsers,
      },
    ],
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: '**',
    redirectTo: BackofficeRouteLocations.CompanyList,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    LoggedInRouteGuard,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
