import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { UserSettingsDTO } from '../../../../../../server/src/dto/user-settings.dto';

@Injectable()
export class BackofficeSettingsService {
  constructor(private _http: CoolHttp) {
  }

  public async getUserSettingsAsync(): Promise<UserSettingsDTO> {
    return await this._http.getAsync('api/settings');
  }
}
