import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UserSettingsDTO } from '../../../../server/src/dto/user-settings.dto';
import { BackofficeUserContextService } from '../shared/services/user-context/backoffice-user-context.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(private _userContextService: BackofficeUserContextService) {
  }

  public isInitialized: boolean;

  public isUserLoggedIn: boolean;
  public user: UserSettingsDTO;

  ngOnInit() {
    this._userContextService.isUserLoggedInObservable
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((userLoggedIn: boolean) => {
        this.isInitialized = true;

        this.isUserLoggedIn = userLoggedIn;

        this.user = this._userContextService.user;
      });
  }

  ngOnDestroy(): void {
  }
}
