<nav class="navbar navbar-light">
  <span class="navbar-brand mb-0 h1">
    <a href="/">
      <img class="img-fluid" src="assets/images/logo-text.png">

      <span class="ml-4">Backoffice</span>
    </a>
  </span>

  <div>
    <ng-content></ng-content>

    <div *ngIf="isInitialized" class="d-flex flex-row">
      <div *ngIf="!isUserLoggedIn">
        Not logged in
      </div>

      <div *ngIf="isUserLoggedIn">
        {{ user.name || user.email }}
      </div>
    </div>
  </div>
</nav>