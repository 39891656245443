import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-analytics-data',
  templateUrl: './company-analytics-data.component.html',
  styleUrls: ['./company-analytics-data.component.scss']
})
export class CompanyAnalyticsDataComponent implements OnInit {

  constructor() { }

  @Input()
  public data: any;

  @Input()
  public name: string;

  public value: string;

  public children: { name: string; value: any; }[];

  public isGroup: boolean;

  ngOnInit(): void {
    if (typeof this.data === 'number' || typeof this.data === 'string') {
      this.value = this.data?.toString();
    } else {
      this.isGroup = true;

      this.children = Object.keys(this.data).map(key => {
        return {
          name: key,
          value: this.data[key],
        };
      });
    }
  }
}
