import { CoolHttp } from '@angular-cool/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BackofficeRouteLocations } from '../shared/routing/backoffice-route-locations.enum';
import { BackofficeCompany } from './backoffice.company';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {

  constructor(private activatedRoute: ActivatedRoute,
              private _http: CoolHttp) {

  }

  public company: BackofficeCompany;

  public RouteLocations = BackofficeRouteLocations;

  public async ngOnInit() {
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this.company = null;

        const companyId = params.companyId;

        if (!companyId) {
          return;
        }

        this.company = await this._http.getAsync(`api/backoffice/companies/${ companyId }`);
      });
  }

  public ngOnDestroy(): void {
  }
}
