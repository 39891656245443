<div class="d-flex flex-row p-2" [class.mat-elevation-z1]="isGroup">
  <div *ngIf="name" class="pr-3 font-weight-bold"
       [class.pl-2]="isGroup"
       [class.pt-2]="isGroup"
       style="min-width: 200px">
    {{ name }}
  </div>
  <div class="flex-grow-1">
    <div *ngIf="!isGroup">{{ value }}</div>
    <div *ngIf="isGroup">
      <div *ngFor="let child of children" class="mt-2">
        <app-company-analytics-data [name]="child.name" [data]="child.value"></app-company-analytics-data>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="!isGroup">