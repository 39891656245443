import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, Inject } from '@angular/core';
import * as bcryptjs from 'bcryptjs';
import { Environment } from '../environments/environment.interface';
import { ENVIRONMENT, WINDOW } from './shared/services/injection-tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(@Inject(WINDOW) private _window: Window,
              @Inject(ENVIRONMENT) private _environment: Environment,
              private _localStorage: CoolLocalStorage) {
    this._redirectToProduction();
  }

  private _redirectToProduction() {
    const keyParam = this._window.location.search.match(/\?dev-key=(.*)/);

    if (keyParam) {
      this._localStorage.setItem('procedure-manager-dev-key', keyParam[1]);
    }

    if (!this._environment.production) {
      const devKey = this._localStorage.getItem('procedure-manager-dev-key');

      if (!devKey || !bcryptjs.compareSync(devKey, this._environment.developmentKeyHash)) {
        this._window.location.href = this._environment.productionAppUrl;
      }
    }
  }
}
