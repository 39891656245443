<div *ngIf="company" class="p-4 h-100 d-flex flex-column">
  <h1>{{ company.name }}</h1>

  <mat-drawer-container class="flex-grow-1">
    <mat-drawer mode="side" opened>
      <mat-nav-list class="pt-0">
        <a mat-list-item [routerLink]="RouteLocations.CompanyListChildren.CompanyUsers"
           routerLinkActive="active">
          Users
        </a>
        <a mat-list-item [routerLink]="RouteLocations.CompanyListChildren.CompanyAnalytics"
           routerLinkActive="active">
          Analytics
        </a>
        <a mat-list-item [routerLink]="RouteLocations.CompanyListChildren.CompanySubscriptions"
           routerLinkActive="active">
          Subscriptions
        </a>
        <a mat-list-item [routerLink]="RouteLocations.CompanyListChildren.CompanySubscriptionSettings"
           routerLinkActive="active">
          Settings
        </a>
        <a mat-list-item [routerLink]="RouteLocations.CompanyListChildren.PartnerSettings"
           routerLinkActive="active">
          Partner
        </a>
      </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
      <div class="p-3">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>