import { CoolHttp } from '@angular-cool/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CompanySubscriptionSettingsDTO } from '../../../../../server/src/dto/comapny-subscription-settings.dto';

@Component({
  selector: 'app-company-subscription-settings',
  templateUrl: './company-subscription-settings.component.html',
  styleUrls: ['./company-subscription-settings.component.scss'],
})
export class CompanySubscriptionSettingsComponent implements OnInit, OnDestroy {
  private _companyId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private _http: CoolHttp) {

  }

  public settings: CompanySubscriptionSettingsDTO;

  public async ngOnInit() {
    this.activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this.settings = null;

        this._companyId = params.companyId;

        if (!this._companyId) {
          return;
        }

        await this._getDataAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public async updateSettingsAsync() {
    await this._http.postAsync(`api/backoffice/companies/${ this._companyId }/subscription-settings`, this.settings);
  }

  private async _getDataAsync() {
    this.settings = await this._http.getAsync(`api/backoffice/companies/${ this._companyId }/subscription-settings`);
  }
}
