import { CoolDialogService } from '@angular-cool/dialogs';
import { CoolHttp } from '@angular-cool/http';
import { Component, OnInit } from '@angular/core';
import { BackofficeCompany } from '../company/backoffice.company';
import { BackofficeRouteNavigationService } from '../shared/routing/backoffice-route-navigation.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {

  constructor(private _http: CoolHttp,
              private _dialogService: CoolDialogService,
              private _routeNavigationService: BackofficeRouteNavigationService) {
  }

  public companies: BackofficeCompany[];

  public async ngOnInit() {
    await this._getDataAsync();
  }

  public async createCompanyAsync(companyName: string, identifierName: string, input: HTMLInputElement, input2: HTMLInputElement) {
    if (!companyName || !identifierName) {
      return;
    }

    const createDialogResult = await this._dialogService.showDialog({
      titleText: `Create company "${ companyName }"?`,
      questionText: `Are you sure you want to create company "${ companyName }"?`,
      confirmActionButtonText: 'Create',
      cancelActionButtonText: 'Cancel',
      confirmActionButtonColor: 'primary',
    });

    if (!createDialogResult.isConfirmed) {
      return;
    }

    await this._http.postAsync('api/backoffice/companies', {
      name: companyName,
      identifierName: identifierName,
    });

    input.value = '';
    input2.value = '';

    await this._getDataAsync();
  }

  public async editCompanyClickAsync(companyId: string) {
    await this._routeNavigationService.goToCompanyAsync(companyId);
  }

  private async _getDataAsync() {
    this.companies = await this._http.getAsync('api/backoffice/companies');
  }
}
