import { CoolDialogsModule } from '@angular-cool/dialogs';
import { CoolHttpModule } from '@angular-cool/http';
import { CoolStorageModule } from '@angular-cool/storage';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library as fontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { CompanyPartnerSettingsComponent } from './company/company-partner-settings/company-partner-settings.component';
import { CompanySubscriptionsComponent } from './company/company-subscriptions/company-subscriptions.component';
import { CompanyUsersComponent } from './company/company-users/company-users.component';
import { CompanyComponent } from './company/company.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { BackofficeRouteNavigationService } from './shared/routing/backoffice-route-navigation.service';
import { ENVIRONMENT, WINDOW } from './shared/services/injection-tokens';
import { BackofficeSettingsService } from './shared/services/settings/backoffice-settings.service';
import { BackofficeStartupService, startupServiceFactory } from './shared/services/startup/backoffice-startup.service';
import { BackofficeUserContextService } from './shared/services/user-context/backoffice-user-context.service';
import { CompanySubscriptionSettingsComponent } from './company/company-subscription-settings/company-subscription-settings.component';
import { CompanyAnalyticsComponent } from './company/company-analytics/company-analytics.component';
import { CompanyAnalyticsDataComponent } from './company/company-analytics/company-analytics-data/company-analytics-data.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    CompanyListComponent,
    CompanyComponent,
    CompanyUsersComponent,
    CompanySubscriptionsComponent,
    LoginComponent,
    CompanySubscriptionSettingsComponent,
    CompanyAnalyticsComponent,
    CompanyAnalyticsDataComponent,
    CompanyPartnerSettingsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,

    MatCardModule,
    MatCheckboxModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,

    CoolStorageModule,
    CoolHttpModule,
    CoolDialogsModule,

    AppRoutingModule,

    FontAwesomeModule,
  ],
  providers: [
    BackofficeRouteNavigationService,

    BackofficeUserContextService,
    BackofficeSettingsService,

    BackofficeStartupService,

    { provide: WINDOW, useValue: window },
    { provide: ENVIRONMENT, useValue: environment },

    { provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [BackofficeStartupService], multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [
    CompanyUsersComponent,
  ],
})
export class AppModule {
  constructor() {
    fontAwesomeLibrary.add(

    );
  }
}
