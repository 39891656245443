import { CoolDialogService } from '@angular-cool/dialogs';
import { CoolHttp } from '@angular-cool/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { PermissionRoleDTO } from '../../../../../server/src/dto/permission-role.dto';
import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from '../../shared/services/injection-tokens';
import { BackofficeCompanyUser } from './backoffice-company-user.interface';

@Component({
  selector: 'app-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.scss'],
})
export class CompanyUsersComponent implements OnInit, OnDestroy {
  private _companyId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private _dialogService: CoolDialogService,
              private _http: CoolHttp,
              @Inject(ENVIRONMENT) private _environment: Environment) {
  }

  public permissionRoles: PermissionRoleDTO[];
  public companyUsers: BackofficeCompanyUser[];

  public inviteLink: string;

  public ngOnInit() {
    this.activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this.companyUsers = null;

        this._companyId = params.companyId;

        if (!this._companyId) {
          return;
        }

        await this._loadDataAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public async onCompanyUserRoleChange(companyUser: BackofficeCompanyUser, change: MatSelectChange) {
    const selectedPermission = this.permissionRoles.find(role => role.id === change.value);

    if (!companyUser || !selectedPermission || companyUser.permissionRole.id === selectedPermission.id) {
      return;

    }

    const changeRoleDialogResult = await this._dialogService.showDialog({
      titleText: `Make "${ companyUser.user.name || companyUser.user.email }" ${ selectedPermission.name }?`,
      questionText: `Are you sure you want to make "${ companyUser.user.name || companyUser.user.email }" ${ selectedPermission.name }?`,
      confirmActionButtonText: `Make ${ selectedPermission.name }`,
      cancelActionButtonText: 'Cancel',
      confirmActionButtonColor: 'primary',
    });

    if (changeRoleDialogResult.isConfirmed) {
      try {
        await this._http.postAsync(`api/backoffice/companies/${ this._companyId }/users/${ companyUser.id }/permission-role`, selectedPermission);
      } catch {
        console.log('Failed to set role');
      }
    }

    await this._loadDataAsync();
  }

  public async generateInviteLinkAsync() {
    const response = await this._http.postAsync(`api/backoffice/companies/${ this._companyId }/invite-link`);

    if (!response.token) {
      return;
    }

    this.inviteLink = `${ this._environment.appUrl }/company/join?joinToken=${ encodeURIComponent(response.token) }`;
  }

  private async _loadDataAsync() {
    this.companyUsers = await this._http.getAsync(`api/backoffice/companies/${ this._companyId }/users`);
    this.permissionRoles = await this._http.getAsync(`api/backoffice/companies/${ this._companyId }/permission-roles`);
  }
}
