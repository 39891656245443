import { CoolHttp } from '@angular-cool/http';
import { CoolSessionStorage } from '@angular-cool/storage';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { UserSettingsDTO } from '../../../../../../server/src/dto/user-settings.dto';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT } from '../injection-tokens';
import { BackofficeSettingsService } from '../settings/backoffice-settings.service';

const SAVED_LOGIN_URL_STORAGE_KEY = 'after-login';

@Injectable()
export class BackofficeUserContextService {
  constructor(private _settingsService: BackofficeSettingsService,
              @Inject(ENVIRONMENT) private _environment: Environment,
              private _http: CoolHttp,
              private _sessionStorage: CoolSessionStorage) {
  }

  public user: UserSettingsDTO;
  public userObservable: ReplaySubject<UserSettingsDTO> = new ReplaySubject<UserSettingsDTO>(1);

  public isUserLoggerIn: boolean;
  public isUserLoggedInObservable: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public async initializeAsync(): Promise<void> {
    await this._tryGetUserContextAsync();
  }

  public goToGoogleLogin() {
    window.top.location.href = `${ this._environment.backendUrl }/api/authentication/google`;
  }

  public goToLogout() {
    window.top.location.href = `${ this._environment.backendUrl }/api/authentication/logout`;
  }

  public async getUserSettingsAsync(): Promise<UserSettingsDTO> {
    if (!this.user) {
      await this.refreshUserSettingsAsync();
    }

    return this.user;
  }

  public async refreshUserSettingsAsync() {
    this.user = await this._settingsService.getUserSettingsAsync();

    this.userObservable.next(this.user);
    this.isUserLoggedInObservable.next(true);
  }

  public saveRouteToNavigateAfterLogin(url: string) {
    this._sessionStorage.setItem(SAVED_LOGIN_URL_STORAGE_KEY, url);
  }

  public getAndClearRouteToNavigateAfterLogin() {
    const url = this._sessionStorage.getItem(SAVED_LOGIN_URL_STORAGE_KEY);

    this._sessionStorage.removeItem(SAVED_LOGIN_URL_STORAGE_KEY);

    return url;
  }

  private async _tryGetUserContextAsync(): Promise<boolean> {
    try {
      await this.getUserSettingsAsync();

      this.isUserLoggerIn = true;
      this.isUserLoggedInObservable.next(true);

      return true;
    } catch (ex) {
      this.isUserLoggerIn = false;
      this.isUserLoggedInObservable.next(false);
    }

    return false;
  }
}
