import { CoolDialogService } from '@angular-cool/dialogs';
import { CoolHttp } from '@angular-cool/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CompanySubscriptionTypes } from '../../../../../server/src/models/company-subscription-types.enum';
import { BackofficeCompanySubscription } from './backoffice-company-subscriptions.interface';

@Component({
  selector: 'app-company-subscriptions',
  templateUrl: './company-subscriptions.component.html',
  styleUrls: ['./company-subscriptions.component.scss'],
})
export class CompanySubscriptionsComponent implements OnInit, OnDestroy {
  private _companyId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private _dialogService: CoolDialogService,
              private _http: CoolHttp) {
  }

  public companySubscriptions: BackofficeCompanySubscription[];

  public CompanySubscriptionTypes = CompanySubscriptionTypes;

  public companySubscriptionTypes = Object.keys(CompanySubscriptionTypes).map(key => {
    return {
      name: key,
      value: CompanySubscriptionTypes[key],
    };
  });

  ngOnInit() {
    this.activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this.companySubscriptions = null;

        this._companyId = params.companyId;

        if (!this._companyId) {
          return;
        }

        await this._getDataAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public async removeCompanySubscriptionAsync(subscription: BackofficeCompanySubscription) {
    const dialogResult = await this._dialogService.showDialog({
      titleText: `Remove "${ subscription.type }" subscription?`,
      questionText: `Are you sure you want to remove "${ subscription.type }" subscription?`,
      confirmActionButtonText: `Remove subscription`,
      cancelActionButtonText: 'Cancel',
      confirmActionButtonColor: 'warn',
    });

    if (!dialogResult.isConfirmed) {
      return;
    }

    await this._http.deleteAsync(`api/backoffice/companies/${ this._companyId }/subscriptions/${ subscription.id }`);

    await this._getDataAsync();
  }

  private async _getDataAsync() {
    this.companySubscriptions = await this._http.getAsync(`api/backoffice/companies/${ this._companyId }/subscriptions`);
  }

  public async createCompanySubscriptionAsync(type: CompanySubscriptionTypes, startDate: string, endDate: string) {
    const dialogResult = await this._dialogService.showDialog({
      titleText: `Create "${ type }" subscription?`,
      questionText: `Are you sure you want to create "${ type }" subscription?`,
      confirmActionButtonText: `Create subscription`,
      cancelActionButtonText: 'Cancel',
      confirmActionButtonColor: 'primary',
    });

    if (!dialogResult.isConfirmed) {
      return;
    }

    await this._http.postAsync(`api/backoffice/companies/${ this._companyId }/subscriptions`, {
      type: type,
      startDate: startDate,
      endDate: endDate,
    });

    await this._getDataAsync();
  }
}
