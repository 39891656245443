import { CoolDialogService } from '@angular-cool/dialogs';
import { CoolHttp } from '@angular-cool/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CompanyDTO } from '../../../../../server/src/dto/company.dto';

@Component({
  selector: 'app-company-partner-settings',
  templateUrl: './company-partner-settings.component.html',
  styleUrls: ['./company-partner-settings.component.scss'],
})
export class CompanyPartnerSettingsComponent implements OnInit, OnDestroy {
  private _companyId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private _dialogService: CoolDialogService,
              private _http: CoolHttp) {

  }

  public subCompanies: CompanyDTO[];

  public allCompanies: CompanyDTO[];

  public async ngOnInit() {
    this.activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this.subCompanies = [];

        this._companyId = params.companyId;

        if (!this._companyId) {
          return;
        }

        await this._getDataAsync();
      });
  }

  public ngOnDestroy(): void {
  }

  public async removeSubCompanyAsync(company: CompanyDTO) {
    const removeDialogResult = await this._dialogService.showDialog({
      titleText: `Remove sub company "${ company.identifierName }"?`,
      questionText: `Are you sure you want to remove sub company "${ company.name }"?`,
      confirmActionButtonText: 'Remove',
      cancelActionButtonText: 'Cancel',
      confirmActionButtonColor: 'warn',
    });

    if (!removeDialogResult.isConfirmed) {
      return;
    }

    await this._http.postAsync(`api/backoffice/companies/${ this._companyId }/sub-companies/${ company.id }`);

    this.subCompanies.splice(this.subCompanies.findIndex(c => c.id === company.id), 1);
  }

  public async addSubCompanyAsync(company: CompanyDTO) {
    if (this.subCompanies.some(c => c.id === company.id)) {
      return;
    }

    await this._http.postAsync(`api/backoffice/companies/${ this._companyId }/sub-companies`, company);

    this.subCompanies.push(company);
  }

  private async _getDataAsync() {
    this.allCompanies = await this._http.getAsync('api/backoffice/companies');

    this.subCompanies = await this._http.getAsync(`api/backoffice/companies/${ this._companyId }/sub-companies`);
  }
}
