import { CoolHttp } from '@angular-cool/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CompanyAnalyticsDTO } from '../../../../../server/src/dto/company.dto';

@Component({
  selector: 'app-company-analytics',
  templateUrl: './company-analytics.component.html',
  styleUrls: ['./company-analytics.component.scss'],
})
export class CompanyAnalyticsComponent implements OnInit, OnDestroy {
  private _companyId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private _http: CoolHttp) {
  }

  public analytics: CompanyAnalyticsDTO;

  public ngOnInit(): void {
    this.activatedRoute.parent.params
      .pipe(untilDestroyed(this))
      .subscribe(async (params: Params) => {
        this.analytics = null;

        this._companyId = params.companyId;

        if (!this._companyId) {
          return;
        }

        await this._getDataAsync();
      });
  }

  public ngOnDestroy() {
  }

  private async _getDataAsync() {
    this.analytics = await this._http.getAsync(`api/backoffice/companies/${ this._companyId }/analytics`);
  }
}
