import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BackofficeRouteLocations } from './backoffice-route-locations.enum';

@Injectable()
export class BackofficeRouteNavigationService {
  constructor(private _router: Router) {

  }

  public async goToLoginAsync() {
    await this._router.navigate([BackofficeRouteLocations.Login]);
  }

  public async goToCompanyAsync(companyId: string) {
    await this._router.navigate([
      ...BackofficeRouteLocations.CompanyListChildrenBase.replace(':companyId', companyId).split('/'),
    ]);
  }
}