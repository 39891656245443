<div>
  <h3>Sub companies</h3>

  <div class="mt-3" *ngIf="allCompanies">
    <table class="table">
      <thead>
      <tr>
        <th>Id</th>
        <th>Name</th>
        <th>Identifier</th>
        <th>Actions</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let company of subCompanies">
        <td>{{ company.id }}</td>
        <td>{{ company.name }}</td>
        <td>{{ company.identifierName }}</td>
        <td>
          <button mat-raised-button color="warn" (click)="removeSubCompanyAsync(company)">Remove</button>
        </td>
      </tr>
      <tr></tr>
      <tr>
        <td colspan="3">
          <mat-form-field class="w-100">
            <mat-select #companySelect>
              <mat-option *ngFor="let company of allCompanies" [value]="company">
                {{ company.name }} ({{ company.identifierName }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <button mat-raised-button color="primary" (click)="addSubCompanyAsync(companySelect.value)">Add</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>