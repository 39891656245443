export const BackofficeRouteLocations = {
  Login: 'login',

  CompanyList: 'companies',

  CompanyListChildrenBase: 'companies/:companyId',
  CompanyListChildren: {
    CompanyUsers: 'users',
    CompanySubscriptions: 'subscriptions',
    CompanySubscriptionSettings: 'subscription-settings',
    PartnerSettings: 'partner-settings',
    CompanyAnalytics: 'analytics',
  }
};