import { CoolHttp, HttpHeader } from '@angular-cool/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../../../environments/environment.interface';
import { ENVIRONMENT, WINDOW } from '../injection-tokens';
import { BackofficeUserContextService } from '../user-context/backoffice-user-context.service';

export function startupServiceFactory(provider: BackofficeStartupService) {
  return () => provider.setupAsync();
}

@Injectable()
export class BackofficeStartupService {
  constructor(private _http: CoolHttp,
              @Inject(ENVIRONMENT) private _environment: Environment,
              @Inject(WINDOW) private _window: Window,
              private _userContextService: BackofficeUserContextService) {

  }

  public async setupAsync() {
    this._http.setWithCredentials(true);
    this._http.registerBaseUrl(this._environment.backendUrl);

    // Do not wait for this to load
    this._tryInitializeUserAsync();
  }

  private async _tryInitializeUserAsync() {
    await this._tryGetXSRFTokenAsync();

    await this._userContextService.initializeAsync();
  }

  private async _tryGetXSRFTokenAsync() {
    const response = await this._http.getAsync('api/settings/xsrf-token');

    if (this._window.location.hostname === 'localhost') {
      this._http.registerGlobalHeader(new HttpHeader('X-XSRF-TOKEN', response.token));
    } else {
      this._http.sendCookieValueInCustomHeader('XSRF-TOKEN', 'X-XSRF-TOKEN');
    }
  }
}