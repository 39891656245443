import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,

  productionAppUrl: 'https://www.proceduremanager.com',
  developmentKeyHash: '$2a$08$AiyrNS9ETPMWVXm7B3xAG.tIYoy1ZuP0CIf.Qm/xtKvsksc65qvsi',

  appUrl: 'https://www.proceduremanager.work',
  backendUrl: 'https://api.proceduremanager.work',
};
