<div>
  <h3>Subscriptions</h3>

  <div class="mt-3">
    <table class="table table-striped">
      <tr>
        <th>Id</th>
        <th>Type</th>
        <th>Start date</th>
        <th>End date</th>
        <th>Actions</th>
      </tr>

      <tr>
        <td></td>
        <td>
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select #subscriptionTypePicker [value]="CompanySubscriptionTypes.Platform">
              <mat-option *ngFor="let companySubscription of companySubscriptionTypes"
                          [value]="companySubscription.value">
                {{ companySubscription.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input matInput #startDatePicker [matDatepicker]="startPicker"
                   placeholder="Choose a start date" (click)="startPicker.open(); $event.stopPropagation()">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field>
            <input matInput #endDatePicker [matDatepicker]="endPicker"
                   placeholder="Choose a end date" (click)="endPicker.open(); $event.stopPropagation()">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </td>
        <td>
          <button mat-raised-button color="primary"
                  (click)="createCompanySubscriptionAsync(subscriptionTypePicker.value, startDatePicker.value, endDatePicker.value)">
            Add subscription
          </button>
        </td>
      </tr>

      <tr *ngFor="let subscription of companySubscriptions">
        <td>
          {{ subscription.id }}
        </td>
        <td>
          {{ subscription.type }}
        </td>
        <td>
          {{ subscription.startDate }}
        </td>
        <td>
          {{ subscription.endDate }}
        </td>
        <td>
          <button mat-raised-button color="warn" (click)="removeCompanySubscriptionAsync(subscription)">
            Remove
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>